import React from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./Gallery.css";
import PageTitle from "../Common/PageTitle";

const photos = [
  {
    src: "/images/gallery/2.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/1.jpg",
    height: 720,
    width: 324,
  },
  {
    src: "/images/gallery/3.jpg",
    height: 715,
    width: 414,
  },
  {
    src: "/images/gallery/4.jpg",
    height: 720,
    width: 405,
  },
  {
    src: "/images/gallery/5.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/6.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/1.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/2.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/3.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/4.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/5.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/6.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/cricket/7.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/football/1.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/football/2.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/football/3.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/football/4.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/football/5.jpg",
    height: 720,
    width: 540,
  },
  {
    src: "/images/gallery/football/6.jpg",
    height: 720,
    width: 540,
  },
];

export default function Gallery() {
  const [index, setIndex] = React.useState(-1);
  const columns = window.innerWidth > 960 ? 7 : 4;
  return (
    <div className="gallery page" id="gallery">
      <PageTitle>Gallery</PageTitle>
      <div className="content">
        <PhotoAlbum
          layout="masonry"
          columns={columns}
          containerProps={{ style: { margin: "50px 0", maxHeight: "90vh" } }}
          columnConstraints={{ minColumns: 8 }}
          componentsProps={{ imageProps: { style: { objectFit: "cover" } } }}
          onClick={({ index }) => setIndex(index)}
          photos={photos}
          defaultContainerWidth={500}
        />
        <Lightbox
          plugins={[Thumbnails]}
          open={index != -1}
          index={index}
          slides={photos}
          close={() => setIndex(-1)}
        />
      </div>
    </div>
  );
}
