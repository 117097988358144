import React from "react";
import { Modal } from "react-responsive-modal";
import Menu from "../Menu/Menu";
import "react-responsive-modal/styles.css";
import "./Hero.css";

export default function Hero() {
  const [formOpen, setFormOpen] = React.useState(false);
  const myRef = React.useRef(null);

  return (
    <header className="hero page" id="home">
      <div className="title-bar">
        <div className="logo-title">
          <img src="/images/logo.svg" alt="logo" height={48} />
        </div>
        <div className="filler"></div>
        <Menu />
      </div>
      <div className="filler"></div>
      <div className="hero-title">
        <div className="sports-title">
          <h2>Get Ready. It's time for</h2>
          <div className="divider"></div>
        </div>
        <h1>5th Edition</h1>
        <div className="hero-actions">
          <div className="cta">
            <div className="basketball">
              <div className="ball">
                <div class="lines"></div>
              </div>
            </div>
            <a onClick={() => setFormOpen(true)}>REGISTER</a>
          </div>
        </div>
      </div>
      <div className="filler"></div>
      <div className="hero-bg">
        <div className="bg-sports" />
      </div>
      {formOpen && (
        <div className="form-conatiner">
          <Modal
            open={formOpen}
            onClose={() => setFormOpen(false)}
            center
            container={myRef.current}
          >
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfcByUBkv6cozk0Z-_ioEbJQ40q83NqTLnXEAjukVYM_cVU8w/viewform?embedded=true"
              width="640"
              height={window.innerHeight - 100}
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </Modal>
        </div>
      )}
    </header>
  );
}
