import React, { useState } from "react";
import "./Menu.css";

const menus = [
  { name: "Home", link: "#home" },
  { name: "About", link: "#about" },
  { name: "Schedule", link: "#schedule" },
  { name: "Gallery", link: "#gallery" },
  { name: "Contact", link: "#contact" },
];

function scrollTo(menu, setOpen) {
  setOpen(false);
  setTimeout(() => {
    const element = document.querySelector(menu.link);
    element.scrollIntoView({ block: "center", behavior: "smooth" });
  }, 100);
}

export default function Menu() {
  const [open, setOpen] = useState(false);
  const menuClass = open ? "menu-container open" : "menu-container";
  const image = open ? "/images/close.svg" : "/images/menu.svg";
  return (
    <>
      <div className={menuClass} id="menu-toggle">
        <a className="menu" onClick={() => setOpen(!open)}>
          <div className="hamburger">
            <img src={image} alt="menu" height={48} />
          </div>
        </a>
        <div className="overlay">
          <nav className="overlay-menu">
            <ul>
              {menus.map((menu, index) => {
                return (
                  <li key={index} onClick={() => scrollTo(menu, setOpen)}>
                    <a href={menu.link}>{menu.name}</a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
