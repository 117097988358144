import React, { useState } from "react";
import { Bracket } from "@sportsgram/brackets";
import "./Schedule.css";
import PageTitle from "../Common/PageTitle";

const roundsConfig = [
  [
    {
      title: "Round 1",
      seeds: [
        {
          id: 1,
          teams: [
            { name: "Othimalai New 11s" },
            { name: "Classic cc, Samichettypalayam" },
          ],
        },
        {
          id: 2,
          teams: [
            { name: "Muthana CC, Saravanampatti" },
            { name: "Red Backs" },
          ],
        },
        {
          id: 3,
          teams: [
            { name: "Thalaphathy CC" },
            { name: "SFCC Ganapathy - Vinoth" },
          ],
        },
        {
          id: 4,
          teams: [
            { name: "Crazy 11s Ammachettipudur" },
            { name: "Striker karamadai" },
          ],
        },
        {
          id: 5,
          teams: [
            { name: "NGCC Chettipalaiyam" },
            { name: "Jolly Fighters Madukkarai" },
          ],
        },
        {
          id: 6,
          teams: [{ name: "Jaihind cc" }, { name: "DUCK 11s" }],
        },
        {
          id: 7,
          teams: [{ name: "Valaiyapalaiyam A Team" }, { name: "NSE Kuppanur" }],
        },
        {
          id: 8,
          teams: [{ name: "Century Hitters Nambiur" }, { name: "RAMNAGER" }],
        },
        {
          id: 9,
          teams: [{ name: "KSCC Ganapathipalayam" }, { name: "Spartans cc" }],
        },
        {
          id: 10,
          teams: [{ name: "SFCC Sirumugai Dileep" }, { name: "ALLIKULAM" }],
        },
        {
          id: 11,
          teams: [{ name: "Danger Boys Ontiputhur" }, { name: "RUF & TUF" }],
        },
        {
          id: 12,
          teams: [{ name: "Murugampalayam cc" }, { name: "TAMILROCKERS" }],
        },
        {
          id: 13,
          teams: [{ name: "TCC Karamadai Pradeep" }, { name: "YSCC" }],
        },
        {
          id: 14,
          teams: [
            { name: "Crazy 11 Karamadai" },
            { name: "Valaiyapalayam Team" },
          ],
        },
        {
          id: 15,
          teams: [
            { name: "Ragavendra Karamadai" },
            { name: "TCC Karumthampatti" },
          ],
        },
        {
          id: 16,
          teams: [
            { name: "PCC (M.Pappampatti) Sathish" },
            { name: "Spartan (Re-Entry)" },
          ],
        },
        {
          id: 17,
          teams: [
            { name: "Amirtham cc Sellanur" },
            { name: "Challangers cc Othakalmandabam" },
          ],
        },
        {
          id: 18,
          teams: [{ name: "FCC Kenathakadavu" }, { name: "VRCC Kallis Team" }],
        },
        {
          id: 19,
          teams: [
            { name: "CBCC Konarpalayam Jp team" },
            { name: "Vetri Venayaga" },
          ],
        },
        {
          id: 20,
          teams: [{ name: "Muthur CC" }, { name: "Fighter Cc Karamadi" }],
        },
        {
          id: 21,
          teams: [{ name: "KSCC" }, { name: "King Boys Karamadai" }],
        },
        {
          id: 22,
          teams: [
            { name: "ValaiyapalaM B Team" },
            { name: "Bcc kaviraj karamadai" },
          ],
        },
        {
          id: 23,
          teams: [
            { name: "Kennedy cc" },
            { name: "Team Bloody x1 Somanur Ajith" },
          ],
        },
        {
          id: 24,
          teams: [
            { name: "KNP 11 Guys Kannarpalaiyam" },
            { name: "Np Warriors" },
          ],
        },
        {
          id: 25,
          teams: [
            { name: "KNP CHALLANGERS" },
            { name: "TCC Thandukarampalayam" },
          ],
        },
        {
          id: 26,
          teams: [{ name: "CBCC Konarpalayam" }, { name: "RAGAVENDRA" }],
        },

        {
          id: 29,
          teams: [{ name: "NFCC" }, { name: "MARVELLOUS Karnampettai" }],
        },
        {
          id: 30,
          teams: [{ name: "TAMILROCKERS" }, { name: "NBCC Chinnakannur" }],
        },
        {
          id: 27,
          teams: [
            { name: "REPOSE CRICKET CLUB NEELAMBUR" },
            { name: "MCC Mathireddypalayam" },
          ],
        },
        {
          id: 28,
          teams: [{ name: "VCC SIMBU" }, { name: "kovai 16 Cc irugur" }],
        },
        {
          id: 31,
          teams: [{ name: "ASCC Team" }, { name: "BCC kaviraj karamadai" }],
        },
        {
          id: 32,
          teams: [{ name: "NFCC Avinashi" }, { name: "KBCC" }],
        },
      ],
    },
    {
      title: "Round 2",
      seeds: [
        {
          id: 33,
          teams: [{ name: "Othimalai New 11s" }, { name: "Red Backs" }],
        },
        {
          id: 34,
          teams: [
            { name: "Thalaphathy CC" },
            { name: "Crazy 11s Ammachettipudur" },
          ],
        },
        {
          id: 35,
          teams: [{ name: "NGCC Chettipalaiyam" }, { name: "Jaihind cc" }],
        },
        {
          id: 36,
          teams: [{ name: "Valaiyapalaiyam A Team" }, { name: "RAMNAGER" }],
        },
        {
          id: 37,
          teams: [{ name: "KSCC Ganapathipalayam" }, { name: "ALLIKULAM" }],
        },
        {
          id: 38,
          teams: [{ name: "Danger Boys Ontiputhur" }, { name: "TAMILROCKERS" }],
        },
        {
          id: 39,
          teams: [{ name: "YSCC" }, { name: "Crazy 11 Karamadai" }],
        },
        {
          id: 40,
          teams: [{ name: "Spartan" }, { name: "Ragavendra Karamadai" }],
        },
        {
          id: 41,
          teams: [
            { name: "Amirtham cc Sellanur" },
            { name: "VRCC Kallis Team" },
          ],
        },
        {
          id: 42,
          teams: [{ name: "Vetri Venayaga" }, { name: "Fighter Cc Karamadi" }],
        },
        {
          id: 43,
          teams: [{ name: "KSCC" }, { name: "Bcc kaviraj karamadai" }],
        },
        {
          id: 44,
          teams: [{ name: "Np Warriors" }, { name: "Kennedy cc" }],
        },
        {
          id: 45,
          teams: [{ name: "TCC Thandukarampalayam" }, { name: "RAGAVENDRA" }],
        },

        {
          id: 47,
          teams: [
            { name: "MARVELLOUS Karnampettai" },
            { name: "NBCC Chinnakannur" },
          ],
        },
        {
          id: 46,
          teams: [
            { name: "REPOSE CRICKET CLUB NEELAMBUR" },
            { name: "kovai 16 Cc irugur" },
          ],
        },
        {
          id: 48,
          teams: [{ name: "BCC kaviraj karamadai" }, { name: "NFCC Avinashi" }],
        },
      ],
    },
    {
      title: "Round 3",
      seeds: [
        {
          id: 49,
          teams: [{ name: "Red Backs" }, { name: "Crazy 11s Ammachettipudur" }],
        },
        {
          id: 50,
          teams: [{ name: "Jaihind cc" }, { name: "RAMNAGER (MAT)" }],
        },
        {
          id: 51,
          teams: [{ name: "Danger Boys Ontiputhur" }, { name: "ALLIKULAM" }],
        },
        {
          id: 52,
          teams: [{ name: "Crazy 11 Karamadai" }, { name: "Spartan" }],
        },
        {
          id: 53,
          teams: [{ name: "Amirtham cc Sellanur" }, { name: "Vetri Venayaga" }],
        },
        {
          id: 54,
          teams: [{ name: "KSCC" }, { name: "Np Warriors" }],
        },
        {
          id: 55,
          teams: [{ name: "Ragavendra Karamadai" }, { name: "NFCC Avinashi" }],
        },
        {
          id: 56,
          teams: [
            { name: "REPOSE CRICKET CLUB NEELAMBUR" },
            { name: "NBCC Chinnakannur" },
          ],
        },
      ],
    },
    {
      title: "Round 4",
      seeds: [
        {
          id: 57,
          teams: [{ name: "Red Backs" }, { name: "RAMNAGER (MAT)" }],
        },
        {
          id: 58,
          teams: [{ name: "ALLIKULAM" }, { name: "Crazy 11 Karamadai" }],
        },
        {
          id: 59,
          teams: [{ name: "Amirtham cc Sellanur" }, { name: "Np Warriors" }],
        },
        {
          id: 60,
          teams: [{ name: "NFCC Avinashi" }, { name: "NBCC Chinnakannur" }],
        },
      ],
    },
    {
      title: "Semi Finals",
      seeds: [
        {
          id: 61,
          teams: [{ name: "Red Backs" }, { name: "Crazy 11 Karamadai" }],
        },
        {
          id: 62,
          teams: [{ name: "Amirtham cc Sellanur" }, { name: "NFCC Avinashi" }],
        },
      ],
    },
    {
      title: "Finals",
      seeds: [
        {
          id: 63,
          teams: [{ name: "Finalist 1" }, { name: "Finalist 2" }],
        },
      ],
    },
  ],
  [
    {
      seeds: [],
    },
  ],
  [
    {
      seeds: [],
    },
  ],
  [
    {
      seeds: [],
    },
  ],
];

const Games = ["Cricket", "Volleyball"];

const getRounds = (selected, setRounds) => {
  return [1, 2].map((round) => {
    return (
      <li
        onClick={() => setRounds(round)}
        key={round}
        className={selected == round ? "active" : ""}
      >
        <span className="link">{Games[round - 1]}</span>
      </li>
    );
  });
};

export default function Schedule() {
  const [rounds, setRounds] = useState(1);
  return (
    <div className="schedule page" id="schedule">
      <PageTitle>5th Edition Schedule</PageTitle>
      <div className="content">
        <div className="schedule-list">
          <ul>{getRounds(rounds, setRounds)}</ul>
        </div>
        <div className="schedule-bracket">
          <Bracket rounds={roundsConfig[rounds - 1]} />
        </div>
      </div>
    </div>
  );
}
