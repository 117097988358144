import React from "react";
import "./Contact.css";
import PageTitle from "../Common/PageTitle";

function getSocialLinks() {
  return (
    <div className="contact-social">
      <a href="">
        <span>Facebook</span>
        <svg
          x="0px"
          y="0px"
          width="8px"
          height="16px"
          viewBox="0 0 8 16"
          enable-background="new 0 0 8 16"
        >
          <path
            fill="#65778C"
            d="M5.193,15.404V8.378h2.357L7.904,5.64H5.193V3.891c0-0.793,0.219-1.333,1.356-1.333L8,2.557
                          V0.108C7.749,0.075,6.889,0,5.887,0C3.796,0,2.365,1.276,2.365,3.62v2.02H0v2.738h2.365v7.026H5.193z"
          ></path>
        </svg>
      </a>
      <a href="">
        <span>Instagram</span>
        <svg
          x="0px"
          y="0px"
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          enable-background="new 0 0 18 18"
        >
          <path
            fill="#65778C"
            d="M9,2C6.757,2,6.4,2.006,5.381,2.052C3.144,2.155,2.155,3.143,2.053,5.381C2.006,6.398,2,6.754,2,9
                          c0,2.243,0.006,2.601,0.052,3.619c0.103,2.237,1.091,3.227,3.328,3.329C6.4,15.994,6.757,16,9,16c2.243,0,2.601-0.006,3.619-0.052
                          c2.236-0.103,3.227-1.093,3.328-3.329C15.994,11.603,16,11.246,16,9c0-2.243-0.006-2.6-0.052-3.62
                          c-0.101-2.234-1.091-3.225-3.329-3.327C11.603,2.006,11.246,2,9,2z M9,0c2.444,0,2.751,0.011,3.712,0.055
                          c3.271,0.15,5.087,1.97,5.233,5.235C17.989,6.25,18,6.556,18,9s-0.011,2.751-0.055,3.711c-0.148,3.271-1.969,5.085-5.234,5.234
                          C11.751,17.989,11.444,18,9,18s-2.75-0.011-3.711-0.055c-3.271-0.149-5.085-1.966-5.235-5.234C0.011,11.751,0,11.444,0,9
                          s0.011-2.75,0.055-3.711c0.15-3.271,1.967-5.085,5.235-5.235C6.25,0.011,6.556,0,9,0z"
          ></path>

          <path
            fill="#65778C"
            d="M9,4.378C6.448,4.378,4.378,6.448,4.378,9c0,2.553,2.069,4.622,4.622,4.622
                          c2.553,0,4.621-2.069,4.621-4.622C13.621,6.448,11.553,4.378,9,4.378z M9,12c-1.657,0-3-1.343-3-3c0-1.657,1.343-3,3-3
                          c1.656,0,3,1.343,3,3C12,10.657,10.656,12,9,12z M13.805,3.116c-0.598,0-1.081,0.484-1.081,1.08s0.483,1.08,1.081,1.08
                          c0.596,0,1.079-0.484,1.079-1.08S14.4,3.116,13.805,3.116z"
          ></path>
        </svg>
      </a>
      <a href="">
        <span>Linkedin</span>
        <svg
          x="0px"
          y="0px"
          width="15px"
          height="15px"
          viewBox="0 0 15 15"
          enable-background="new 0 0 15 15"
        >
          <path
            fill="#65778C"
            d="M3.254,14.113H0.155V4.817h3.099V14.113z M1.705,3.437C0.764,3.437,0,2.668,0,1.718
                          C0,0.77,0.762,0,1.705,0c0.942,0,1.704,0.77,1.704,1.718C3.409,2.668,2.645,3.437,1.705,3.437z M14.1,14.113h-3.096V9.682
                          c0-2.915-3.102-2.669-3.102,0v4.432H4.803V4.817h3.099v1.693C9.254,4.007,14.1,3.821,14.1,8.909V14.113z"
          ></path>
        </svg>
      </a>
    </div>
  );
}

export default function Contact() {
  return (
    <div className="contact page" id="contact">
      <PageTitle>Contact</PageTitle>
      <div className="content">
        <div className="contact-col">
          <div className="contact-email">
            <a className="link" href="tel:+919994439989">
              <span className="contact-icon">
                <svg
                  fill="#1C2033"
                  width="52"
                  height="52"
                  version="1.1"
                  id="lni_lni-phone"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                >
                  <g>
                    <path
                      d="M48.6,60.9c-8.1,0-19.6-5.8-30.2-15.8C4,31.3-2.1,15.9,4.3,9.1c0.3-0.3,0.7-0.6,1.1-0.8l8.1-4.6c2.1-1.2,4.7-0.6,6.1,1.4
		l5.9,8.4c0.7,1,1,2.2,0.7,3.4C26,18,25.3,19,24.3,19.7L20.8,22c2.7,3.9,10,13.6,21.6,20.5c0.1,0.1,0.2,0,0.2,0l2.5-3.4
		c1.4-1.9,4.1-2.4,6.2-1.1l8.8,5.6c2.1,1.3,2.7,4,1.4,6.1l-4.8,7.7c-0.3,0.4-0.6,0.8-0.9,1.1C54,60.1,51.5,60.9,48.6,60.9z
		 M15.8,7.6C15.8,7.6,15.7,7.6,15.8,7.6l-8.2,4.6c-3.8,4.1,0.9,17.2,14,29.6c13.3,12.6,27,17.1,31.4,13.3l0,0c0,0,0,0,0,0l4.8-7.7
		l-8.8-5.6c-0.1,0-0.2,0-0.2,0l-2.5,3.4c-1.4,1.9-4.1,2.4-6.1,1.2c-12.5-7.5-20.3-17.9-23.1-22c-0.7-1-0.9-2.2-0.7-3.3
		c0.2-1.2,0.9-2.2,1.9-2.8l3.5-2.3l-5.8-8.3C15.9,7.7,15.8,7.6,15.8,7.6z"
                    />
                  </g>
                </svg>
              </span>
              <span>+91 99944 39989</span>
            </a>
          </div>
        </div>
        <div className="contact-col">
          <div className="contact-links">
            <h3>Follow Us</h3>
            {getSocialLinks()}
          </div>
        </div>
        <div className="contact-col">
          <div className="contact-email">
            <a className="link" href="mailto:guruprabhu12@gmail.com">
              <span className="contact-icon">
                <svg
                  fill="#1C2033"
                  width="52"
                  height="52"
                  version="1.1"
                  id="lni_lni-envelope"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                >
                  <path
                    d="M56,9.6H8c-3.4,0-6.3,2.8-6.3,6.3v32.4c0,3.4,2.8,6.3,6.3,6.3h48c3.4,0,6.3-2.8,6.3-6.3V15.8C62.3,12.4,59.4,9.6,56,9.6z
	 M56,14.1c0.1,0,0.2,0,0.3,0L32,29.7L7.7,14.1c0.1,0,0.2,0,0.3,0H56z M56,49.9H8c-1,0-1.8-0.8-1.8-1.8V18.5l23.4,15
	c0.7,0.5,1.5,0.7,2.3,0.7c0.8,0,1.6-0.2,2.3-0.7l23.4-15v29.7C57.8,49.2,57,49.9,56,49.9z"
                  />
                </svg>
              </span>
              <span>guruprabhu12@gmail.com</span>
            </a>
          </div>
        </div>
        <div class="contact-bg"></div>
      </div>
    </div>
  );
}
