import React from "react";
import { useInView } from "react-intersection-observer";

export default function PageTitle(props) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div ref={ref} className={inView ? "in-view" : ""}>
      <div className="page-title">{props.children}</div>
    </div>
  );
}
