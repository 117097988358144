import About from "./About/About";
import "./App.css";
import Contact from "./Contact/Contact";
import Gallery from "./Gallery/Gallery";
import Hero from "./Hero/Hero";
import Schedule from "./Schedule/Schedule";

function App() {
  return (
    <div className="app">
      <Hero />
      <About />
      <Schedule />
      <Gallery />
      <Contact />
    </div>
  );
}

export default App;
