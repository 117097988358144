import React from "react";
import PageTitle from "../Common/PageTitle";
import "./About.css";

export default function About() {
  return (
    <div className="about page" id="about">
      <PageTitle>About Us</PageTitle>
      <div className="content">
        <div className="mission section">
          <h6>Our Mission</h6>
          <div className="mission-container">
            <div className="mask founder"></div>
            <p>
              Fostering excellence through teamwork and dedication, our club
              empowers athletes to reach their full potential. We prioritize
              inclusivity, sportsmanship, and personal growth, creating a
              community where champions thrive on and off the field.
            </p>
          </div>
        </div>
        <div className="story section">
          <h6>Our Story</h6>
          <div className="story-container">
            <p>
              It all began in 2018 with a vision, a dream to create a haven
              where athletes could thrive, pushing the boundaries of skill and
              determination.
            </p>
            <div className="mask admin"></div>
          </div>
        </div>
        <svg height="0" width="0">
          <defs>
            <clipPath id="svgPath">
              <path
                d="M482.629 458.401C399.681 506.91 248.38 574.37 138.481 492.75 28.58 411.13-31.344 202.042 18.63 74.574c49.974-127.469 210.63-62.055 376.336-9.787 165.707 52.267 70.499 68.206 166.048 180.867 95.549 112.662 4.562 164.24-78.386 212.747z"
                id="a"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
}
